import React, { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"

import logoSvg from "../../images/logo.svg"
import SocialLinks from "../elements/SocialLinks"
import Navbar from "./Navbar"

const BRIDGE_LINK = "https://mainnet.odinprotocol.io/validators";
const STAKE_LINK = "https://ping.pub/odin/staking";
const RUNA_LINK = "https://runa.odinprotocol.io/";
const SIGHT_LINK = "https://sight.odinprotocol.io/";

const sections = [

  // { name: "Architecture", href: "#architecture", current: false },
  // { name: "Wallace", href: "#wallace", current: false },
  { name: "partners & Ecosystem", href: "#partners&Ecosystem", current: false },
  { name: "Use Cases", href: "#use-cases", current: false },
  { name: "Roadmap", href: "#roadMap", current: false },
]

const links = [
  {
    name: "Docs",
    href: "https://docs.odinprotocol.io",
    current: false,
  },
  // { name: "Mainnet", href: "http://mainnet.odinprotocol.io/", current: false },
  // { name: "ODIN Explorer", href: "https://scan.odinprotocol.io/", current: false },
]



export default function Header() {
  return (
    <Popover className="relative">
      {/* DESKTOP */}
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-10">
        <div className="flex justify-between items-center py-6 md:justify-start">
          {/* Desktop 1st */}
          <div className="hidden lg:flex justify-start lg:w-0 lg:flex-1">
            <SocialLinks />
          </div>
          {/* Desktop 2nd */}
          <div className="flex justify-start lg:justify-center lg:w-0 lg:flex-1">
            <span className="sr-only">ODIN</span>
            <img className="h-6 w-auto sm:h-8" src={logoSvg} alt="ODIN" />
          </div>
          {/* Desktop 3th */}
          <div className="hidden sm:flex items-center sm:justify-end sm:flex-1 lg:w-0">
            <a
              href={RUNA_LINK}
              target="_blank"
              rel="noopener noreferrer"
              className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 mr-4 text-textPrimary border-2 border-borderPrimary text-base font-customExtraBold hover:text-textSecondary hover:border-textPrimary"
            >
              Runa
            </a>
            <a
              href={SIGHT_LINK}
              target="_blank"
              rel="noopener noreferrer"
              className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 mr-4 text-textPrimary border-2 border-borderPrimary text-base font-customExtraBold hover:text-textSecondary hover:border-textPrimary"
            >
              Sight
            </a>
            <a
              href={STAKE_LINK}
              target="_blank"
              rel="noopener noreferrer"
              className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 mr-4 text-textPrimary border-2 border-borderPrimary text-base font-customExtraBold hover:text-textSecondary hover:border-textPrimary"
            >
              Stake
            </a>
          </div>
          {/* Hamburguer menu icon */}
          <div className="-mr-2 -my-2 pl-6 lg:hidden">
            <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-textPrimary border-2 border-borderPrimary hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
        </div>
      </div>

      {/* NAVBAR */}
      <Navbar sections={sections} links={links} />

      {/* MOBILE */}
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-20 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
        >
          <div className="rounded-lg ring-1 ring-black ring-opacity-5 bg-backgroundSecondary border border-borderPrimary">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img className="h-8 w-auto" src={logoSvg} alt="ODIN" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-backgroundSecondary rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="flex mt-12 mb-6 sm:hidden">
                <a
                  href={BRIDGE_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 mr-4 text-textPrimary border-2 border-borderPrimary text-base font-customExtraBold hover:text-textSecondary hover:border-textPrimary"
                >
                  Bridge
                </a>
              </div>
              <div className="mt-12 mb-6">
                <nav className="grid gap-y-8 sm:grid-cols-2 sm:gap-x-8">
                  {sections.map(item => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                    >
                      <span className="text-2xl font-customBold text-textPrimary">
                        {item.name}
                      </span>
                    </a>
                  ))}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6 rounded-b-lg border-4 border-backgroundSecondary bg-gradient-to-r from-pink30 via-purple30 to-blue30">
              <div className="grid gap-y-8 md:grid-cols-3 md:gap-x-8">
                {links.map(item => (
                  <a
                    key={item.name}
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-2xl font-customExtraBold text-backgroundSecondary"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
